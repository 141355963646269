'use client';

import {LicenseInfo} from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_KONEKSA_MUI_X_PRO_LICENSE_KEY || '');

/**
 * A dummy client side component that merely returns null, but should be added to the root layout
 * to ensure that the MUI Pro license key is set.
 * @see https://mui.com/x/introduction/licensing/#where-to-install-the-key
 */
export default function MuiXLicense() {
  return null;
}
