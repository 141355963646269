'use client';
import {theme} from '@/styles/theme';
import {AppRouterCacheProvider, AppRouterCacheProviderProps} from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import type {ReactNode} from 'react';

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: {children: ReactNode; options?: AppRouterCacheProviderProps['options']}) {
  const {options, children} = props;

  return (
    <AppRouterCacheProvider options={options}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppRouterCacheProvider>
  );
}
