export type LoggerConfig = {
  logLevel: string;
  serviceName: string;
  servicePriority: string;
};

export function initializeLogger(loggerConfig: LoggerConfig) {
  // initialize logger environment variables
  process.env.LOG = loggerConfig.logLevel;
  process.env.SERVICE_NAME = loggerConfig.serviceName;
  process.env.SERVICE_PRIORITY = loggerConfig.servicePriority;
}
