'use client';
import {initializeLogger, LoggerConfig} from '@/services/logger';

/**
 * A dummy client side component that initializes the logger on the client side. Should be added to the root layout.
 */
export default function LoggerInitializer(props: {config: LoggerConfig}) {
  initializeLogger(props.config);
  return null;
}
